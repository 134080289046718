export default [
  {
    path: '/administrative',
    name: 'administrative',
    meta: {
      title: 'Administrativo',
      module: 'administrative',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-shield-account', 
      isBaseLayout: true,
      requiresAuth: true,
      permission: "RideryOffice_Payment_Methods/show_administrative_module"
    },
    component: () =>
      import(
        /* webpackChunkName: "api-partners" */ '@/views/administrative/AdministrativeLayout.vue'
      ),
    children: [
      {
        path: 'payment-incidents',
        name: 'payment-incidents',
        meta: {
          adminLayoutNav: true,
          requiresAuth: true,
          layout: 'admin',
          title: 'Incidencias de pagos',
          module: 'administrative',
          icon: 'mdi-account-credit-card ',
          allowedTotalDocumentsForIndexPages: true,
          requiresAuth: true,
          permission: "RideryOffice_Payment_Methods/show_payment_incident_module"
        },
        component: () =>
          import(
            /* webpackChunkName: "api-partners-trips" */ '@/views/administrative/PaymentIncidents.vue'
          )
      },
    ]
  }
];
