const state = {
  sortFilter: {
    options: [
      { text: 'ID', value: 'unique_id' },
      { text: 'ID Conductor', value: 'provider_unique_id' },
      { text: 'Nombre admin ', value: 'provider_name' },
      { text: 'Sucursal', value: 'branch' },
      { text: 'Fecha de creación', value: 'created_at' }
    ],
    optionValue: 'created_at',
    sortModes: [
      {
        text: 'Ascendente',
        value: 1
      },
      {
        text: 'Descendente',
        value: -1
      }
    ],
    sortModeValue: -1
  },
  searchFilter: {
    searchValue: '',
    options: [
      {
        text: 'ID',
        value: 'unique_id'
      },
      {
        text: 'ID Conductor',
        value: 'provider_unique_id'
      },
      {
        text: 'Cédula',
        value: 'provider_id.document_number'
      },
      {
        text: 'Correo del administrador',
        value: 'admin_id.email'
      }
    ],
    optionValue: 'unique_id'
  },
  incidentTabFilter: {
    optionValue: 'ATI/ATC',
    options: [
      {
        text: 'ATI/ATC',
        value: 'ATI/ATC'
      },
      {
        text: 'LIQ',
        value: 'LIQ'
      },
      {
        text: 'Emprende con Ridery',
        value: 'ECR'
      }
    ]
  },
  incidentSubTabFilter: {
    optionValue: 'pending',
    options: [
      {
        text: 'Pendiente',
        value: 'pending'
      },
      {
        text: 'Aprobado',
        value: 'approved'
      },
      {
        text: 'Rechazado',
        value: 'refused'
      }
    ]
  },

  date: [],
  pagination: {
    page: 1,
    pages: 1,
    limit: 10
  },
  expandTable: false
};

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },
  resetFilters({ commit }) {
    commit('SET_FILTERS', state);
  }
};

const getters = {
  filters: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
